<template>
  <nav class="admin-nav navbar fixed-top navbar-expand-lg navbar-light justify-content-between">
    <router-link tag="a" to="/admin" class="navbar-brand">
      <img src="../../assets/img/admin/logo.svg" alt="logo" class="logo">
    </router-link>
    <div id="navbarNav" class="nav-content">
      <ul class="navbar-nav">
        <li class="nav-item" @click="$emit('toggle-side')">
          <img src="../../assets/img/admin/side-menu.svg" class="toogle-side" style="width: 3vh;">
        </li>
      </ul>
    </div>

    <span>
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >{{ displayName }}</a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" @click.prevent="logout">
          <img src="../../assets/img/admin/logout.png" class="logout-logo">Logout
        </a>
      </div>
    </span>
  </nav>
</template>

<script>
import axios from 'axios'

export default {
  name: "admin-top-nav",
  data: function() {
    return {
      displayName: null,
    }
  },
  created: function() {
    this.displayName = window.$cookies.get('username');
  },
  methods: {
    async logout() {
      try {
        let res = await axios({
          method: "post",
          url: "http://testdeployd.aezypay.com/users/logout",
          withCredentials: true,
          data: {
            sid: window.$cookies.get('sid'),
          },
        })
        .then(function(res) {
          window.$cookies.remove('sid');
          window.$cookies.remove('username');
        })
        .catch(function(error) {
          console.log(error);
        });
        this.$router.push({ path: '/login' });
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-nav {
  background: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  //  box-shadow: 0px 6px 25px 0px rgba(189,187,189,1);

  .navbar-brand {
    width: 15vh;
    padding: 0;
  }
  .nav-content {
    flex-grow: 1;
    li {
      cursor: pointer;
    }
  }
  .dropdown-menu {
    border: none;
    border-top: #f1f1f2;
    .logout-logo {
      width: 2vh;
      margin-right: 8px;
    }
  }
}
</style>
