<template>
  <div class="side-bar" :class="{'open': showSide}">
    <router-link to="/admin/announcements">
      <img
        src="../../assets/img/admin/announcement-grey.svg"
        alt="announce-logo"
        class="announce-logo"
      >
      <span v-show="showSide">Announcement</span>
    </router-link>
    <router-link to="/admin/careers">
      <img src="../../assets/img/admin/careers-grey.svg" alt="career-logo" class="career-logo">
      <span v-show="showSide">Careers</span>
    </router-link>
  </div>
</template>

<script>
  export default {
  name: "admin-side-bar",
  props: {
    showSide: Boolean
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.side-bar {
  background-color: #2c3139;
  padding-top: 10px;
  width: 90px;
  transition: 0.5s;
  overflow-x: hidden;

  a {
    display: block;
    padding: 20px;
    text-decoration: none;
    font-size: 25px;

    .career-logo {
      width: 5vh;
      height: 4vh;
    }
    .announce-logo {
      width: 3.5vh;
      margin-left: 1vh;
    }
  }
  // open-side-bar
  &.open {
    width: 290px;
    text-align: left;
    img {
      margin-right: 15px;
    }
    span {
      font-size: 18px;
      color: white;
    }
  }
}
</style>
