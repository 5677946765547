<template>
  <div class="admin-home">
    Welcome!
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/admin/AdminNav.vue";
import SideBar from "@/components/admin/SideBar.vue";

export default {
  name: "admin-home",
  head: {
    title: { inner: "Admin" }
  },
  data: function() {
   return {
     
   }
  },
  methods: {
  }
};
</script>

<style lang="scss">
</style>
